.startAndEndDateDiv {
  width: 100%;
}

.headingStyle {
  width: 100%;

  .colorPicker input {
    margin-top: 42px;
  }
}
.holidayTable{
  width: 70%;
  margin: auto;
}
.holidayForm{
  width: 45%;
  margin: auto;
  .holidayCard{
    padding: 15px !important;
  }
}
.holidayRowContainer{
  display: flex;
  flex-direction: column;
}
.buttonStyle {
  text-align: center;
  width: 100%;
  margin-top: 10px;

  button:disabled {
    display: inline !important;
    padding: 0px 12px !important;
  }

  button {
    background-color: #ab322d;

    &:focus {
      background-color: #ab322d;
      display: inline-flex !important;
    }

    &:hover {
      background-color: #ab322d;
      box-shadow: none !important;
    }
  }
}

.bodyTextAreaStyle {
  width: 100%;

  textarea {
    width: 100% !important;
  }
}
