.homeLayout {
    background-color: #F5F5F5;

    .noteMaterialCol {
        margin-bottom: 0;
    }
    .topHeader {
        margin: 20px 30px;
    }

    .leftSection {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 30px 10px 30px;

      .cardsTitle {
        margin: 4px 0 0 0;
      }
    }
}
.filtersIconKanban {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 4px;
  position: absolute;
  top: 14px;
  right: 10px;
  color: white;
  cursor: pointer;
}
.listViewFiltersIcon {
  align-items: center;
  grid-gap: 10px;
  gap: 4px;
  cursor: pointer;
  margin-left: 9px !important;
}

@media (max-width:768px)
{
  .homeLayout {
    width: 100%;

    .topHeader {
      margin: 10px 8px;
    }

    .leftSection {
      margin: 0px 8px 10px 8px;
    }
  }
}
