@import '../../styles/variable.scss';

.dashboard-date-picker {
  .ant-picker-range {
    gap: 12px;
  }
}
  a.dashbaord-circle-graph {
    max-width: 185px;
    margin: auto;
    border-radius: 50%;
    overflow: hidden;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #000;
    width: 100%;
    display: block;
    svg{
      width: auto !important;
      height: auto !important;
    }
}
.dashbaord-circle-graph:hover {
  border: 4px solid $secondary-color; /* Add a blue border on hover */
}
.dashboard-collapsible-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .collapsible-title {
    font-weight: bold;
  }
}
.dashboard-main-page ul.tabs {
  margin-bottom: 0;
  height: 60px;

  .tab {
    height: 60px;
    line-height: 60px;
  }
}

.dashboard-main-page {
  width:100%;
  padding:25px 35px;
  
  .row {
    margin-bottom: 0px !important;
  }

  .col {
    padding: 0px !important;
  }

  .dashboard-collapsible-section {
    margin: 1.5rem 0px;
    box-shadow: none;

    .card-content {
      padding: 0px !important;
      overflow: hidden;
      border-radius: 8px;

      .collapsible {
        border: none !important;
        margin: 0.3rem 0px;

        .collapsible-header {
          border: none !important;

          i {
            margin-right: 0;
          }
        }
      }
    }
  }
}

.col .exportCsvButtonDashboard {
  float: inline-end;
}

i.material-icons.searchIconDashboard {
  position: absolute;
  margin-top: 15px;
}

input.searchInputDashboard {
  padding-left: 27px !important;
}

@media (max-width:768px) {
  .ant-picker-panels {
    max-width: 288px;
  }
}
