@import '../../styles/variable.scss';

.toolBoxCustom {
  .ant-tabs-nav-wrap {
    background-color: white;
    padding: 15px;
  }
}

.containerCustomStyle {
  .ant-card-head {
    background-color: $primary-color;
    color: $secondary-color;
    border: 1px solid trasparent;
  }
  .ant-card-body {
    border: 1px solid trasparent;
    padding: 24px !important;
    .ant-card-meta-detail{
      display: flex;
      align-items: center;
    }
    .ant-card-meta-title {
      white-space: unset !important;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;   
      -webkit-line-clamp: 1;
    }
  }

}

.ant-card-meta {
  &.showFullText {
    .ant-card-meta-detail {
      .ant-card-meta-title {
        white-space: normal !important;
        overflow: visible !important;
        padding-right: 50px !important;
        display: block;
      }
    }
  }

  &.AiDataText {
    .ant-card-meta-detail {
      .ant-card-meta-title {
        padding-right: 50px !important;
      }
    }
  }
}
@media (max-width:768px) {
  .toolBoxCustom {
     .ant-select-selector{
      height: 100% !important;
     }
   }  
}
