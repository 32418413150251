.inputDiv {
  padding: 12px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;

  .searchInput {
    input {
      border: 1px solid #d9d9d9;
      border-radius: 6px;
      height: 45px !important;
      margin: 0;
      padding: 4px 11px;
      font-size: 14px;
      box-sizing: border-box;
    }

    .ant-input-group-addon {
      display: none !important;
    }
  }

  .searchSelect {
    width: calc(50% - 8px) !important;
    height: 45px !important;
  }
}

.AIEditModal {
  max-height: 72vh !important ;
    .modal-content {
      padding: 24px 20px 10px;
    .AITextEditor {
      .AIquillEditor {
        .ql-container { 
          height: calc(70vh - 165px);
          overflow: auto;
        }
      }
    }
  }
}
