.deskMode{
  display: flex !important;
}
.datePicker input {
  margin: unset;
  width: 72px;
  border-bottom: unset !important;
}
.dashboardloader{
  margin: 4px;
}

@media (max-width:768px)
{
  
  .mobileMode {
    display: flex;
  }

  .datePicker {
    margin-bottom: 12px;
  }

  .datePicker input {
    margin: unset;
    width: 72px;
    border-bottom: unset !important;
  }
}
