.csat_table {
	tr {
		th {
			text-align: center;
		}

		td {
			text-align: center;

			a {
				color: #039be5 !important;
			}

			.ratedComment_Section.text-align-left {
				overflow: hidden;
				display: -webkit-box;
				-webkit-line-clamp: 4;
				-webkit-box-orient: vertical;
				text-overflow: ellipsis;
			}
		}
	}
}
