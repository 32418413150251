@import '../../styles/variable.scss';

.outerContainer {
  width: 52%;
  margin: auto;
  min-height: 100vh !important;

  .container {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 16px;
    padding-top: 40px;

    .card {
      cursor: pointer;
      box-shadow: 0px 0px 7px 0px #848282;

      .descAndIconWrapper {
        p {
          margin-block-start: 0;
          margin-block-end: 0;
          height: 65px;
        }

        .arrowIcon {
          font-size: 18px;
          display: flex;
          color: $primary-color;
        }

        .reprompt {
          height: auto;
          margin: 10px;
          text-decoration: underline;
          font-size: 19px;
        }
      }
    }

    .selectedCard {
      border: solid 1px $primary-color;
    }

    .rePrompt {
      margin-left: auto;
      font-size: 14px;
      cursor: pointer;
      display: none;
    }

    .showPrompt {
      display: block;
    }
  }

  .dataWrapper {
    margin-top: 16px;
    width: 100%;

    .cardDataStyle {
      margin-top: 16px;

      .saveBtnChange {
        position: absolute;
        top: 20px;
        right: 40px;
      }

      .fullTextButton {
        position: absolute;
        right: 10px;
        top: 27px;
        color: rgba(0, 0, 0, 0.88);
        font-weight: 600;
        font-size: 16px;
      }
    }
  }
}

.tableCustomStyle {
  width: 80%;
  margin: auto;
  padding-top: 40px;
  min-height: 100vh !important;

  .tableViewData {
    width: 100%;
    background-color: #fff;
    border-bottom: 1px solid #eee;
    position: relative;
    padding: 0 40px;
    box-shadow: 0 0 13px #848282;
  }
}

@media (max-width:768px) {
  .outerContainer {
    width: 100%;
    min-height: 100vh !important;
  
    .container {
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
      padding-top: 0px;
    }
  } 
  .tableCustomStyle {
    width: 100%;
    padding-top: 0px;
  
    .tableViewData {
      overflow-x: auto;
      padding: 0px 15px;
    }
  }
  .dataWrapper {
    padding: 0 20px !important;
  }
}
