@import '../../../styles/variable.scss';

.scriptHint {
  font-weight: 600;
}

.AiToolboxModalBox.generateButton,
.AiToolboxModalBox.generateButton:hover,
.AiToolboxModalBox.generateButton:focus {
  margin-top: 12px;
  background-color: $primary-color !important;
  color: #fff !important;
  height: 45px;
  width: 100px;
}
.generateButton:disabled {
  background-color: $disable-color !important;
  color: black !important;
}

.AiToolboxModalBox.cancelButton,
.AiToolboxModalBox.cancelButton:hover,
.AiToolboxModalBox.cancelButton:focus {
  margin-top: 12px;
  background-color: $primary-color !important;
  color: #fff !important;
  height: 45px;
  width: auto;
}
.AiToolboxInputBox.addButton,
.AiToolboxInputBox.addButton:hover,
.AiToolboxInputBox.addButton:focus {
  background-color: $primary-color !important;
  color: #fff !important;
  height: 44px !important;
  padding: 4px 15px !important;
}
.AiToolboxInputBox.crossButton,
.AiToolboxInputBox.crossButton:hover,
.AiToolboxInputBox.crossButton:focus {
  background-color: $primary-color !important;
  color: #fff !important;
  height: 44px !important;
  padding: 4px 17px !important;
}
