.topHeaderContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    margin-top: 12px;

    .bellIcon {
        font-size: 20px;
        cursor: pointer;
    }
}
.top-bar-right nav ul li a.notification-nav
{
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color:#737171;
}

@media (max-width:768px)
{
  .topHeaderContainer {
    gap: 0px;
    padding-top: 14px;
    margin-top: 0;
  }
}
