  @import '../../styles/variable.scss';

  .container {
      margin: 0 30px;
      padding: 25px 35px;

      .cardsTitleDiv {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;

          .cardsTitle {
              margin: 4px 0 0 0;
              font-size: 28px;
          }
      }

      .filtersAndTotalCount {
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          margin-bottom: 16px;

          .selectBoxWrapper {
              display: flex;
              gap: 12px;
              align-items: center;

              .viewAsStyle {
                  width: 250px;
                  height: 50px;

                  input {
                      border-bottom: 0 !important;
                      box-shadow: none !important;
                  }
              }

              .exportButton,
              .exportButton:hover,
              .exportButton:focus {
                  background-color: $primary-color !important;
                  padding: 0 30px;
                  height: 49px;
              }
          }
      }
  }

  @media (max-width:768px) {
      .container {
          .filtersAndTotalCount {
              .selectBoxWrapper {
                  display: block;
              }
          }
      }
  }