@import "../../../styles/variable.scss";

.msgCountAndDivWrapper {
  display: flex;
  justify-content: space-between;
  width: 97%;
  align-items: center;

  .searchFieldAndCreateBtnWrapper {
      display: flex;
      gap: 10px;

      .viewAsStyle {
        width: 250px;
        height: 50px;
        input {
          border-bottom: 0 !important;
          box-shadow: none !important;
        }
      }

      .searchInput {
        vertical-align: middle;
        width: 250px;
        height: 50px;

        input {
          border-bottom: unset;
          height: unset;
          margin: unset;
          font-size: 14px;
        }
      }
  }
}

.newMessageBtn, .newMessageBtn:hover, .newMessageBtn:focus {
    background-color: $primary-color !important;
    padding: 4px 15px;
    border-radius: 6px;
}

.cardWrapper {
    margin: 18px 0;
    height: calc(100vh - 260px);
    overflow: scroll;
    
    .cards {
      width: 98%;
      margin-bottom: 10px;
      cursor: pointer;

      .titleWrapper {
        display: flex;
        justify-content: space-between;
      }
  
      .avatarAndMessageWrapper {
        display: flex;
        align-items: center;
        gap: 10px;
         .messageListAvatar{
            background-color: $primary-color;
            width: 25px;
            height: 25px; 
            line-height: 25px;
         }
        .msgAndCountWrapper {
          display: flex;
          justify-content: space-between;
          
          .iconAndTextContainer {
            gap: 5px;
            align-items: center;
            display: flex;
          }
  
          .greyFontColor {
            color: #616161;
          }
          .greenFontColor {
            color: green;
          }
        }
        
        .dateStyle {
          font-size: 12px;
          color: #6d6e6f;
        }
      }
    }
  
    .paginationDiv {
      margin-top: 2rem;
    }
    .resolveBtn, button:focus {
      background-color: $primary-color;
      color: #FFF;
      font-size: 14px;
    }
    
    .disableResolveBtn, button:disabled {
      border: 1px solid #999999 !important;
      background-color: #cccccc;
      color: #666666;
      cursor: not-allowed;
      font-size: 14px;
    }
}



.deskMode {
  display: none;
}

.mobileMode {
  display: block;
}

@media (max-width:768px)
{
  .msgCountAndDivWrapper {
    display: block;
    .searchFieldAndCreateBtnWrapper {
      margin-bottom: 8px;
        .viewAsStyle {
          width: 100%;
        }
  
        .searchInput {
          width: 100%;
          input {
            font-size: 12px;
          }
        }
    }
  }

  .cardWrapper {
    margin-top: 7px;
    .cards {
      .titleWrapper {
        span {
          white-space: nowrap;
          width: 120px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .avatarAndMessageWrapper {
        .msgAndCountWrapper {
          margin-top: 8px;
          padding-right: 8px;
          span {
            font-size: 12px;
          }
        }
        
        .dateStyle {
          font-size: 10px;
        }
      }
    }
  }

  .deskMode {
    display: block;
  }
  .mobileMode {
    display: none;
  }

  .newMessageBtn, .newMessageBtn:hover, .newMessageBtn:focus {
    font-size: 12px;
    height: 28px;
}
}
