@import '../../styles/variable.scss';

.layout {
    padding: 20px 30px;

    .headingAndButtonWrapper {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        margin: 20px 0 4px 0px;

        button, button:hover, button:focus {
            background-color: $primary-color;
        }
        .heading {
            font-size: 25px;
            font-weight: 600;
        }
        .reloadIconAndButtonWrapper {
            display: flex;
            gap: 16px;
            align-items: center;

            img {
                cursor: pointer;
            }

            .markAsReadBtn,
            .markAsReadBtn:hover,
            .deleteNotificationBtn,
            .deleteNotificationBtn:hover {
                background-color: $primary-color;
                padding: 4px 15px;
                border-radius: 6px;
                border: 1px solid transparent;
            }

            .deleteIcon {
                font-size: 16px;
            }
        }
    }
}

.showMoreBtnContainer {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 0 0 35px 0;

    button, button:hover, button:focus {
      background-color: $primary-color !important;
    }
}

.loader {
    width: 100%;
    text-align: center;
    margin-top: 42px;
}

@media (max-width:768px)
{
    .layout {
        padding: 20px 8px;

        .heading {
            font-size: 24px;
        }

        .headingAndButtonWrapper {
            .reloadIconAndButtonWrapper {
                gap: 8px;
                .markAsReadBtn,
                .markAsReadBtn:hover,
                .deleteNotificationBtn,
                .deleteNotificationBtn:hover {
                    padding: 2px 8px;
                }
            }
        }
    }

    .clientName {
        font-size: 14px;
    }
    .message {
        font-size: 10px;
    }
    .date {
        font-size: 12px;
    }
    .mobileMode {
        display: none;
    }
}
