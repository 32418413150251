@import '../../styles/variable.scss';

.list-group-item {
    .active {
        background-color: $primary-color;
    }
}

.ant-btn-primary:disabled,
.ant-btn-primary:disabled:hover {
    border-color: #d9d9d9 !important;
    background-color: $disable-color !important;
}

.notificationCards {
    .ant-card-body {
        padding: 15px !important;
    }
}

.notificationUnseenCards {
    .ant-card-body {
        border: 1px solid $primary-color;
        background-color: $secondary-color;
    }
}

.notificationSeenCards {
    .ant-card-body {
        border: 0;
    }
}
