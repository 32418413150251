.ant-picker-cell-inner {
  text-align: center;
}

input[type=text]:not(.browser-default):focus:not([readonly]) {
  border-bottom: 0;
}

.selectCustomStyle .ant-select-selector {
  font-size: 14px !important;
}

.disableSelectBox .ant-select-selector {
  background-color: #ffffff !important;
  color: #000000 !important;
}

.selectBoxStyle .ant-select-selector .ant-select-selection-item {
  display: flex;
  align-items: center;
}

@media (max-width:768px)
{
  span.ant-select-selection-placeholder, span.anticon.anticon-down.ant-select-suffix, span.anticon.anticon-search {
    font-size: 11px;
  }
  .selectCustomStyle .ant-select-selector {
    font-size: 12px !important;
  }
}
