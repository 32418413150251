$theme-color: var(--theme--Color);

.leave_drawer {
    .drawer_footer_button {
        background-color: $theme-color !important;
        font-size: 13px;
        padding: 4px 18px;
        border-radius: 6px;
        border: 1px solid transparent;
        color: white;
        margin-right: 5px;
        display: unset;

        &:disabled {
            padding: 4px 18px;
            background-color: #999999 !important;
            color: white !important;
            border-radius: 6px;
            border: 1px solid #999999;
        }

        &:hover {
            color: white !important;
        }
    }

    .leave_form_container {
        .leave_input {
            margin-bottom: 20px;

            .date_picker {
                height: 50px;
                width: 100%;
            }

            .notify_dropdown {
                width: 100%;
                height: 50px;
            }
        }

    }
}

.add_leave_button {
    background-color: $theme-color !important;
    font-size: 13px;
    padding: 4px 18px;
    border-radius: 6px;
    border: 1px solid transparent;
    color: white;
    margin-right: 5px;
    display: unset;

    &:hover {
        color: white !important;
    }
}

.leave_table {
    tr {
        td {
            .delete_icon {
                background-color: $theme-color !important;
                font-size: 13px;
                padding: 1px 10px;
                border-radius: 6px;
                border: 1px solid transparent;
                color: white;
                margin-right: 5px;
                display: unset;

                &:hover {
                    color: white !important;
                }
            }
        }
    }
}
