@import '../../../styles/variable.scss';

.chatCover {
  max-height: 58vh !important;
  padding-top: 40px;
  height: 65vh;
}

.chatBoxInput {
  border: 1px solid rgb(205, 213, 222) !important;
  border-radius: 9px !important;
  min-height: 48px !important;
  padding: 16px 30px 12px 9px !important;
  min-width: 100% !important;
}

.sendIcon {
  color: $primary-color;
}

.sendButton:hover {
  background-color: transparent;
}

.loader {
  margin-top: 6rem;
  width: 100%;
}

.repliedChatInBackground {
  margin: 0 0.75rem;
  padding: 1.3rem 0.75rem;
  background: lightgrey;
  border-radius: 6px;
  height: 34px;
  align-items: center;
  display: flex;
  gap: 4px;

  .replyChatAndCloseIconWrapper {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    cursor: pointer;
  }
}

@media (max-width:768px)
{
  .chatCover {
    margin-top: 0;
  }
}
