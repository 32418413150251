.ant-table-wrapper table {
    border-collapse: collapse;
}

.ant-table-cell {
    border-right: 1px rgba(171, 50, 45, 0.1);
    border-left: 1px rgba(171, 50, 45, 0.1);
}

.datePickerDiv .ant-picker .ant-picker-input input {
    margin: 0;
    height: 44px;
    width: 250px;
}

.datePickerDiv .ant-picker .ant-picker-input input:focus {
    border-bottom: 0;
    box-shadow: 0 0 0 0;
}

@media (max-width:768px)
{
    .datePickerDiv .ant-picker {
        padding: 6px 9px 6px 4px;
    }

    .datePickerDiv .ant-picker .ant-picker-clear,
    .datePickerDiv .ant-picker .ant-picker-input input {
        display: none;
    }

    
    .ant-table-cell {
        padding: 8px 8px !important;
    }

    ::-webkit-scrollbar {
        height: 2px;
    }
}
