@import '../../../styles/variable.scss';

.messageListContainer {
    .ant-modal-footer button {
        display: inline-block;
    }

    .ant-modal-footer .ant-btn-primary,
    .ant-modal-footer .ant-btn-primary:hover {
        background-color: $primary-color !important;
    }

    .ant-btn-primary:disabled,
    .ant-btn-primary:disabled:hover {
        border-color: #d9d9d9 !important;
        background-color: $disable-color !important;
    }

    .ant-modal-footer .ant-btn-default,
    .ant-modal-footer .ant-btn-default:hover {
        background-color: #ffffff !important;
    }

    .ant-modal-footer button:focus {
        padding: 4px 15px;
        border-radius: 6px;
    }
}

.pagination li a {
    font-size: 14px !important;
    font-family: "Poppins", sans-serif !important;
    font-weight: 400 !important;
}

.msgCountAndDivWrapper {
    .ant-input-affix-wrapper {
        height: 50px;
    }

    .ant-input-group-addon button.ant-input-search-button {
        height: 50px;
    }
}

@media (max-width:768px) {
    .msgCountAndDivWrapper {
        .ant-input-affix-wrapper {
            height: 32px;
        }

        .ant-input-search-button {
            height: 28px !important;
        }
    }

    .pagination li a {
        font-size: 12px !important;
    }

    .cardsStyle .ant-card-head {
        padding: 0 10px;
    }
}
