@import '../../styles/variable.scss';

.container {
	width: 100%;
	padding: 0 24px;
	margin-top: 24px;

	.innerContainer {
		width: 45%;
		padding: 0 50px;
		padding-top: 8px;
		gap: 28px;
		margin: auto;
		display: flex;
		background-color: #ffffff;
		box-shadow: 10px 10px 5px #aaaaaa;
		justify-content: center;

		.divWrapper {
			width: 100%;

			.inputLabel {
				font-family: 'Poppins', 'sans-serifsans-serif';
				font-weight: 500;
			}
		}
	}
}

.searchInput {
	vertical-align: middle;
	width: 100%;

	input {
		border-bottom: unset !important;
		height: unset !important;
		margin: unset !important;
		font-size: 14px !important;
	}
}

.autoCompleteInputWrapper {
	position: relative;
}

.customInputDiv {
	background-color: #ffffff;
	padding: 2px 10px;
	border-radius: 10px;
	height: 250px;
	overflow: hidden;
	overflow-y: scroll;
	display: none;
	border-left: 1px solid lightgray;
	border-right: 1px solid lightgray;
	border-bottom: 1px solid lightgray;
	position: absolute;
	z-index: 99;
	width: -webkit-fill-available;

	.optionListItem {
		font-weight: normal;
		cursor: pointer;
		margin-block-start: 0px;
		margin-block-end: 0px;
		padding: 8px 0px;
		border-bottom: 1px solid lightgray;
	}
}

.viewAsStyle {
	width: 100%;

	input {
		border-bottom: 0 !important;
		box-shadow: none !important;
		margin: 0 !important;
	}
}

.btnWrapper {
	margin: 10px 0 20px;

	.submitButton,
	.submitButton:hover,
	.submitButton:focus {
		background-color: $primary-color !important;
	}

	.submitButton:disabled {
		background-color: $disable-color !important;
	}

	button,
	button:hover,
	button:focus {
		background-color: $primary-color !important;
	}
}

.cardWrapper {
	width: 100%;
	padding: 0 24px;
	margin-top: 12px;

	.card {
		width: 100%;
		margin-bottom: 12px;
	}
}

.emptyDataElement {
	padding-top: 30px;
}

.materialCard {
	margin: 0 24px;
	margin-top: 24px;

	button,
	button:hover {
		background-color: $primary-color;
	}
}

.datePickerAndSelectWrapper div {
	display: flex;
	gap: 16px;

	.selectBox {
		height: 36px;
		width: 200px;
	}
}

@media (max-width:768px) {
	.container {
		padding: 0 8px;
		margin-top: 12px;

		.innerContainer {
			flex-direction: column;
			gap: 0;
			box-shadow: 5px 5px 5px #aaaaaa;
			width: 100%;
			padding: 0 12px;
		}
	}

	.searchInput {
		width: 100%;

		input {
			font-size: 12px !important;
		}
	}

	.viewAsStyle {
		width: 100%;
		font-size: 12px !important;
	}

	.submitButton,
	.submitButton:hover,
	.submitButton:focus {
		margin-top: 18px;
		background-color: $primary-color;
		width: 100%;
	}

	.submitButton:disabled {
		background-color: $disable-color;
	}
}