.searchFieldAndSelectBtnWrapper {
  display: flex;
  padding: 18px 1px;
  align-items: center;

  .selectField {
    display: flex;
    margin-left: 30px !important;
    position:relative;

    .selectFieldForSearch {
      border-bottom: 0 !important;
      box-shadow: none !important;
      width: 250px !important;

      input {
        border: 1px solid #d9d9d9;
        border-radius: 6px;
        height: 45px !important;
        margin: 0;
        padding: 4px 11px;
        font-size: 14px;
        box-sizing: border-box;
      }

      button {
        display: none !important;
      }

    }

    .searchButton {
     height: 100% !important;
      box-shadow: none !important;
      position: absolute !important;
      font-size: 17px !important;
      right: 0;
      display: flex;
      border-left: 0;
      border-radius: 0px 6px 6px 0px;
      align-items: center;
      justify-content: center;
    }

  }

  .searchField {
    vertical-align: middle;


    .selectFieldForType {
      border-radius: 6px;
      height: 45px !important;
      margin: 0;
      font-size: 14px;
      width: 250px !important;

      input {
        width: 300px;
      }
    }
  }
}
.AIDataTable tbody tr .aiTableActionButton { 
   display: flex;
   gap: 5px;
}

@media (max-width:768px) {
   .aiTableActionButton{
     display: flex;
   }
   .searchFieldAndSelectBtnWrapper {  
    .selectField {
      margin-left: 15px !important;
    }  
    .searchField {
      vertical-align: middle;
      .selectFieldForType {
        width: 120px !important;
      }
    }
  }
  .AIDataTable {
   thead tr {
      white-space: nowrap;
      .dataColumns{
        min-width: 200px;
      }
    }
  }
}
