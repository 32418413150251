@import '../../styles/variable.scss';

.CardDetailsLayout {
  background-color: #F5F5F5 !important;
  .title {
    font-size: 25px;
    padding-left: 2rem;
  }
  .wrapper {
    display: flex;
    justify-content: space-between;
    .tabsContainer {
      padding-left: 2rem;
      width: 50% !important;
    }

    .chatSectionContainer {
      width: 50%;
    }
  }
}

.addTitleField, .addMessageField {
  font-size: 14px !important;
}

.chatDateDiv {
  text-align: center; margin: 14px 0;
  .chatDate {
    background-color: #8FA0AF;
    border-radius: 999px;
    padding: 2px 8px;
    color: #FFFFFF;
    font-size: 13px;
  }
}

.optionAndCommentContainer {
  margin-top: 12px;
  .rowWrapRight {
    display: flex;
    justify-content: flex-end;
    gap: 15px;

    .commentAndTimeContainer {
      color: $primary-color;

      .commentMessage {
        .chatMessage {
          background: #E8F2FE;
          padding: 16px;
          color: #0E1114;
          box-shadow: 0px 0px 0px 1px rgba(219, 222, 223, 0.44);
          border-radius: 16px 16px 0px 16px;
          margin: 2px 0;
        }
        span {
          color: #616C76;
          font-size: 12px;
        }
      }     
    }
  }
  div .roundImgStyle,
  div .avatarStyle {
    background-color: $primary-color;
    margin-top: 1.8rem;
  }
  .rowWrapLeft {
    display: flex;
    gap: 15px;

    .commentAndTimeContainer {
      color: $primary-color;

      .commentMessage {
        .chatMessage {
          background: #EEF1F4;
          padding: 16px;
          color: #0E1114;
          border-radius: 16px 16px 16px 0px;
          margin: 2px 0;
        }
        span {
          color: #616C76;
          font-size: 12px;
        }
      }
    }
  }
}

.clientName {
  color: $primary-color;
  margin-bottom: 2px;
}

.editAndDeleteIconWrapper {
  display: flex;
  gap: 5px;
  margin-top: 5px;
  justify-content: flex-end;

  .editIcon, .deleteIcon {
    font-size: 18px !important;
    cursor: pointer;
  }
  .deleteIcon {
    color: $primary-color !important;
  }
  .replyIcon {
    color: #616C76;
    margin-right: 4px;
    cursor: pointer;
  }
  .markAsDoneIcon, .thumbsUpIcon {
    cursor: pointer;
  }
}

.cards {
  top: 30%;
}

.deskMode {
  display: none;
}

@media (max-width:768px)
{
  .CardDetailsLayout {
    .title {
      padding-left: 8px;
    }
    .wrapper {
      flex-direction: column;
      .tabsContainer {
        padding-left: 8px;
      }
      .chatSectionContainer {
        width: calc(100% - 60px);
        position: absolute;
        background: #FFFFFF;
        height: 100%;
        padding: 0 8px;

        .title {
          margin-top: 0;
          padding: 0;
          font-size: 20px;
        }

        .greyFontColor {
          color: #616161;
        }
    
        .arrowIconAndTextWrapper {
          display: flex;
          align-items: center;
          gap: 7px;
          padding: 0;
        }
    
        .arrowIconAndTextWrapper:focus {
          background-color: none;
        }
      }
    }
  }

  .clientName b {
    font-size: 12px;
  }
  
  .chatDateDiv .chatDate {
    font-size: 12px;
  }
  
  .chatMessage {
    font-size: 12px;
    padding: 12px 16px;
  }

  .editAndDeleteIconWrapper {
    .replyIcon,
    .editIcon svg,
    .deleteIcon svg {
      width: 14px;
      height: 14px;
    }
  }

  .optionAndCommentContainer {
    .rowWrapLeft,
    .rowWrapRight {
      align-items: center;

      div .roundImgStyle,
      div .avatarStyle {
        width: 25px;
        height: 25px;
        font-size: 12px;
        align-items: center;
        display: flex;
        margin-top: 0 !important;
      }
    }
  }
}
